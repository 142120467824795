body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
}

.app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.demo-widget {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.loanpass-widget {
  flex: 1;
}

.demo-controls {
  display: flex;
  flex-direction: row;
}

.demo-controls > .control-widget {
  margin: 0 5px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  height: 250px;
}

details {
  border: 1px solid #ccc;
  margin: 5px 0;
  padding: 5px;
}

iframe {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 650px;
  border: 1px solid #ccc;
  margin: 5px 0;
}

form > * {
  display: block;
  margin: 10px 0;
}

label p {
  margin: 0;
}
